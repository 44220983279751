import React from "react"

export default function error() {
  return (
    <>
      <h1>
        Due to some reason your payment is not successful. Please try again
      </h1>
    </>
  )
}
